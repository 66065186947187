import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import { debounce } from "lodash";


export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("tenant/getAll");

  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);

  if (router.currentRoute.params.id_tenant)
    tenantFilter.value = parseInt(router.currentRoute.id_tenant);

  const tableColumns = [
    { label: "seproban.id_alarm", key: "id_alarm", sortable: true },
    { label: "seproban.path", key: "path", sortable: true },
    { label: "seproban.order", key: "order", sortable: true },
    { label: "seproban.is_send", key: "is_send", sortable: true },
    { label: "seproban.sent_at", key: "sent_at", sortable: true },
    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const action = ref("list");

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("seproban/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_tenant: tenantFilter.value,
        action: action.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    action,
    tenantOptions,
    tenantFilter,
    refetchData,
  };
}
